<template>
  <!-- Desc: View: product & category targeting for - Prouct targeting adgroups  -->
  <div
    class="card min-height-card u-flex-direction-column u-width-100 u-display-flex adgroupProfile"
  >
    <div v-if="showAddProfile">
      <profile
        v-bind="profile.props"
        :selections="selections"
        v-on="profile.events"
      />
    </div>
    <div
      v-if="confirmStatusChange"
      class="u-spacing-pt-l"
    >
      <component
        :is="confirmStatusChange.component"
        v-bind="confirmStatusChange.props(this)"
      />
    </div>
    <div
      v-if="selections.length && !showAddProfile && !confirmStatusChange"
      class="u-spacing-pt-l"
    >
      <actions-bar
        :close-fn="closeActionBar"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        :no-action-message="'No actions can be taken for the active profile'"
        @open-workspace="openActionsWorkspace"
      />
    </div>
    <tableWrapper
      :table-data-component-key="tableDataComponentKey"
      :class="showActionsBar && 'campaign_details_table_header_padding'"
      :date-filter="dateFilter"
      :tab-metadata="tabMetadata"
      :widget="widget"
      :header-options="headerOptions"
      :context="context"
      :entity-config="entityConfig"
    />
  </div>
</template>
<script>
import profile from '@/pages/entity_details/configs/walmart/widget_templates/profile.vue';
import Vue from 'vue';
import { validateHeightAndWidthOfLogo } from '@/components/ams/campaign_creation/walmart_steps/profile/util';
const tableWrapper = () =>
  import('@/pages/entity_details/common_components/tableWrapper.vue');
const actionsBar = () => import('@/components/widgets/actionsBar.vue');
const reviewDataComp = () =>
  import('@/pages/entity_details/configs/walmart/components/reviewData.vue');
const tableSwitchComponent = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/tableSwitchComponent.vue'
  );
const confirmStatusChangeComponent = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/approveRec.vue'
  );
const reviewStatusComponent = () =>
  import('@/pages/entity_details/configs/walmart/components/reviewStatus.vue');
const preview = {
  props: {
    imgUrl: {
      type: String,
      default: ''
    },
    altText: {
      type: String,
      default: 'Brand Logo'
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    }
  },
  template: `
   <img :src="imgUrl" :alt="altText" :style="{'width':width || '300px', 'height':height || '180px'}" />
  `
};

function checkImageDimensions(imgObject, error) {
  return new Promise((resolve) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(imgObject);
    img.onload = function () {
      const errorMessage = validateHeightAndWidthOfLogo(
        this.height,
        this.width
      );
      if (errorMessage) {
        error.logo = errorMessage;
      }
      URL.revokeObjectURL(objectUrl);
      resolve();
    };
    img.src = objectUrl;
  });
}

export default {
  components: {
    tableWrapper,
    actionsBar
  },
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    },
    parentEntityData: {
      default: null,
      type: Object
    }
  },
  data() {
    const context = this;
    return {
      getState: null,
      selections: [],
      stateChangeRequestPayload: null,
      addProfileRequestPayload: null,
      editProfileRequestPayload: null,
      updateMetadata: null,
      tableGridAPI: null,
      context,
      widget: {},
      tabConfig: null,
      showAddProfile: false,
      showAddProfileButton: false,
      initialProfile: {
        props: {
          config: {
            edit: false,
            validations: {
              logo: async (logo, error) => {
                if (!logo) {
                  error.logo = 'Logo is mandatory';
                } else if (logo && typeof logo !== 'string') {
                  if (logo.size > 204800) {
                    error.logo = 'The logo should be within 200kb.';
                  } else if (!logo.type.includes('png')) {
                    error.logo = 'The logo should be a png file';
                  } else {
                    await checkImageDimensions(logo, error);
                  }
                }
                return error;
              },
              headline: (headline, error) => {
                if (!headline?.trim?.()) {
                  error.headline = 'Headline is mandatory';
                } else if (headline.length > 45) {
                  error.headline =
                    'The Headline text should not exceed 45 characters.';
                }
                return error;
              },
              brandName: (brandName, error) => {
                if (!brandName?.trim?.()) {
                  error.brandName = 'Brand name is mandatory';
                } else if (brandName.length > 35) {
                  error.brandName =
                    'The Brand name text should not exceed 35 characters.';
                }
                return error;
              },
              url: (url, error) => {
                if (!url?.trim?.()) {
                  error.url = 'URL is mandatory';
                } else if (!url.startsWith('https://www.walmart.com')) {
                  error.url =
                    'The click URL should be a valid URL starting with https://www.walmart.com';
                }
                return error;
              }
            }
          }
        },
        events: {
          apply: this.updateProfile,
          cancel: this.cancel
        }
      },
      profile: {
        props: {}
      },
      reviewData: null,
      gridOptions: {
        context: { componentParent: this },
        domLayout: 'normal',
        onRowDataChanged: this.rowDataChanged
      },
      confirmStatusChange: null
    };
  },
  computed: {
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    },
    headerOptions() {
      const that = this;
      const obj = {};
      if (this.tabMetadata?.widgets[0]) {
        const isReviewDataVisible =
          this?.tabConfig?.headerOptions?.(this).length > 0;
        const displayType = isReviewDataVisible ? 'flex' : 'grid';
        obj[this.tabMetadata.widgets[0]] = {
          props: {
            headerOptions: [...(this?.tabConfig?.headerOptions?.(this) || [])],
            enableManageColumns: !this.showActionsBar,
            manageColumnGridPosition: !isReviewDataVisible ? 4 : undefined,
            manageColumnFlexOrder: isReviewDataVisible ? 1000 : 300,
            enableDownload: false,
            headerContainerGridStyles: {
              'grid-template-columns': '1fr auto auto',
              display: this.selections.length ? 'none' : displayType
            },
            searchParams: {
              show: false
            },
            rowSelection: {
              mode: 'single',
              onSelectionChanged: this.handleSelectionChange
            },
            rowHeight: 110,
            gridOptions: this.gridOptions
          }
        };
      }
      return obj;
    },
    allowedActions() {
      return this.tabConfig?.allowedActions?.(this) || [];
    },
    showActionsBar() {
      return (
        this.selections.length &&
        !this.showAddProfile &&
        !this.confirmStatusChange
      );
    }
  },
  watch: {
    tableDataComponentKey: function (nv, ov) {
      if (nv !== ov) {
        this.tabConfig?.apiConfigs?.fetchMetadata?.(this);
      }
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.stateChangeRequestPayload = tabConfig?.stateChange?.requestPayload;
    this.addProfileRequestPayload = tabConfig?.addProfile?.requestPayload;
    this.editProfileRequestPayload = tabConfig?.editProfile?.requestPayload;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.widget = {
      ':widget': this.tabMetadata.widgets[0],
      ':orderByList': [],
      ':dimensionNameValueList': [],
      keepExistingDimensionNameValueList: true,
      replaceEntityInDimensionNameValueList: {
        ':adgroup_id': this.entityId
      },
      replaceEntityInOperationsObject: {
        ':system': `campaign_${this.parentEntityId}`
      }
    };
    Vue.component('profile', profile);
    Vue.component('reviewDataComp', reviewDataComp);
    Vue.component('preview', preview);
    Vue.component('tableSwitchComponent', tableSwitchComponent);
    Vue.component('confirmStatusChangeComponent', confirmStatusChangeComponent);
    Vue.component('reviewStatusComponent', reviewStatusComponent);
    this.profile = { ...this.initialProfile };
  },
  methods: {
    switchFunctionToCall() {}, // todo:- remove it for all instances in both dashboard config and UI
    handleSelectionChange(item1, item2) {
      this.tableGridAPI = item2?.api;
      this.selections = item1;
    },
    openActionsWorkspace(actionId) {
      if (actionId === 'enabledProfile') {
        this.confirmStatusChange = {
          component: 'confirmStatusChangeComponent',
          props: (vueRef) => ({
            config: {
              heading: 'ENABLE PROFILE(1 profile selected)',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              message: 'Status for selected item will be enabled',
              apply: () => {
                this.callStateChange();
              },
              handleCancel: () => {
                this.closeActionBar();
              }
            }
          })
        };
      } else {
        this.editProfile();
      }
    },
    closeActionBar() {
      this.tableGridAPI?.deselectAll();
      this.selections = [];
      this.confirmStatusChange = null;
    },
    callStateChange() {
      if (this.tabConfig?.changeStatus) {
        return this.tabConfig.changeStatus(this);
      }
    },
    editProfile() {
      this.showAddProfile = true;
      this.profile = this.tabConfig?.transformData?.(
        this.selections,
        this.initialProfile
      );
    },
    updateMetadataAction(payloadArr, entityName) {
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: payloadArr,
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        callAdgroupFetch: false,
        entityName,
        routeData: this.$route
      });
      this.closeActionBar();
      this.showAddProfile = false;
    },
    addProfile() {
      this.closeActionBar();
      this.showAddProfile = true;
      this.profile = { ...this.initialProfile };
    },
    updateProfile(payload) {
      if (this.profile?.props?.config?.edit) {
        if (this.tabConfig?.editProfile) {
          this.tabConfig.editProfileAction(this, payload);
        }
      } else {
        if (this.tabConfig?.addProfile) {
          this.tabConfig.addProfileAction(this, payload);
        }
      }
    },
    cancel() {
      this.showAddProfile = false;
      this.closeActionBar();
    },
    rowDataChanged() {
      if (this.gridOptions.api.rowModel.rowsToDisplay.length === 1) {
        this.showAddProfileButton = true;
      } else {
        this.showAddProfileButton = false;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.min-height-card {
  min-height: 630px;
}
</style>

<style lang="css">
.campaign_details_table_header_padding .header-container-cwc {
  padding: 0px;
}
.adgroupProfile div[col-id^='walmart_cm_profile_status'].ag-cell {
  margin: 0;
  padding: 0;
}
</style>
