<template>
  <div class="u-height-100">
    <div
      class="u-display-flex u-height-100 u-spacing-pl-l u-spacing-pt-l u-position-relative"
      :class="{ 'show-loader': isLoading || isAllowed }"
    >
      <section
        v-if="isLoading || isAllowed"
        class="loaderContainer"
      >
        <loader
          class="fill--parent"
          :loading="isLoading"
          :color="'#007cf6'"
        />
        <div
          v-if="isAllowed"
          class="fillMessage fill--parent u-font-size-3"
        >
          Multiple profiles are not allowed
        </div>
      </section>
      <div
        class="u-display-flex u-flex-direction-column u-flex-1 u-border-right u-border-color-grey-xxx-light u-border-width-s"
      >
        <!-- Profile 1 -->
        <section class="u-spacing-pr-xxxl u-spacing-mr-m">
          <!-- Upload branch logo -->
          <div>
            <div class="u-font-size-5 u-font-weight-600 u-color-grey-base">
              Profile:
            </div>
            <div
              class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl u-display-flex u-flex-align-items-center"
            >
              <ImageGuideLinesTippyVue to-element-name="infoTooltip" />
              <span>Brand logo:</span
              ><rb-icon
                name="infoTooltip"
                class="u-color-grey-lighter rb-icon--medium u-spacing-ml-xs u-cursor-pointer"
                icon="info-circle-fill"
              >
              </rb-icon>
            </div>
            <div
              class="u-spacing-mt-m u-display-flex u-flex-align-items-center u-position-relative"
            >
              <div class="u-width-20 u-spacing-mr-m u-flex-align-self-center">
                <img
                  ref="logoRef"
                  class="brand-name-logo"
                />
              </div>
              <div class="u-spacing-pl-s u-font-size-7 u-color-blue-base">
                <div>
                  <input
                    id="fileElm"
                    ref="fileElm"
                    data-cy="uploadLogo"
                    class="u-display-none"
                    type="file"
                    accept="image/png"
                    :multiple="false"
                    @change="processFile"
                  />
                  <span
                    class="u-cursor-pointer"
                    @click="chooseFile"
                    >Upload logo</span
                  >
                </div>
              </div>
              <!-- Error Message -->
              <div
                v-if="(errorText || {}).logo"
                class="u-position-absolute errorMessage u-font-size-7 u-color-red-base u-display-flex u-flex-align-items-center"
                :style="{ left: 0, bottom: '-2rem' }"
              >
                <rb-icon
                  class="u-flex-0 rb-icon--small"
                  :icon="'error-fill'"
                />
                <div class="u-flex-0 u-spacing-ml-xxs u-spacing-mt-xxxs">
                  {{ errorText.logo }}
                </div>
              </div>
            </div>
          </div>
          <!-- Brand name -->
          <div
            class="u-spacing-pt-l u-display-flex u-flex-direction-column u-position-relative"
          >
            <span
              data-cy="BrandNameHeading"
              class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl"
              >Brand Name:</span
            >
            <rb-input
              v-model="brandName"
              placeholder="Enter brand name"
              class="u-spacing-mt-s"
              @input="
                updateProfileInfo({
                  brandName: brandName,
                  headline: headline,
                  url: url,
                  file: logo
                })
              "
            />
            <div class="u-spacing-mt-xs u-flex-align-self-flex-end">
              <span class="u-font-size-8 u-font-weight-400 u-color-grey-lighter"
                >Max of 35 characters</span
              >
            </div>
            <!-- Error Message -->
            <div
              v-if="(errorText || {}).brandName"
              class="u-position-absolute errorMessage u-font-size-7 u-color-red-base u-display-flex u-flex-align-items-center"
              :style="{ left: 0, bottom: '-1rem' }"
            >
              <rb-icon
                class="u-flex-0 rb-icon--small"
                :icon="'error-fill'"
              />
              <div class="u-flex-0 u-spacing-ml-xxs u-spacing-mt-xxxs">
                {{ errorText.brandName }}
              </div>
            </div>
          </div>

          <!-- Headline -->
          <div class="u-spacing-mt-l u-position-relative">
            <span
              data-cy="HeadlineHeading"
              class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl"
              >Headline:</span
            >
            <div class="u-display-flex u-flex-direction-column u-spacing-mt-s">
              <rb-input
                v-model="headline"
                placeholder="Enter headline"
                class=""
                @input="
                  updateProfileInfo({
                    brandName: brandName,
                    headline: headline,
                    url: url,
                    file: logo
                  })
                "
              />
              <div class="u-spacing-mt-xs u-flex-align-self-flex-end">
                <span
                  class="u-font-size-8 u-font-weight-400 u-color-grey-lighter"
                  >Max of 45 characters</span
                >
              </div>
            </div>
            <!-- Error Message -->
            <div
              v-if="(errorText || {}).headline"
              class="u-position-absolute errorMessage u-font-size-7 u-color-red-base u-display-flex u-flex-align-items-center"
              :style="{ left: 0, bottom: '-1rem' }"
            >
              <rb-icon
                class="u-flex-0 rb-icon--small"
                :icon="'error-fill'"
              />
              <div class="u-flex-0 u-spacing-ml-xxs u-spacing-mt-xxxs">
                {{ errorText.headline }}
              </div>
            </div>
          </div>

          <!-- Url -->
          <div class="u-spacing-mt-l u-position-relative">
            <span
              data-cy="URLHeading"
              class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl"
              >URL:</span
            >
            <div class="u-display-flex u-flex-direction-column u-spacing-mt-s">
              <rb-input
                v-model="url"
                placeholder="Enter url"
                class=""
                @input="
                  updateProfileInfo({
                    brandName: brandName,
                    headline: headline,
                    url: url,
                    file: logo
                  })
                "
              />
            </div>
            <!-- Error Message -->
            <div
              v-if="(errorText || {}).url"
              class="u-position-absolute errorMessage u-font-size-7 u-color-red-base u-display-flex u-flex-align-items-center"
              :style="{ left: 0, bottom: '-2.5rem' }"
            >
              <rb-icon
                class="u-flex-0 rb-icon--small"
                :icon="'error-fill'"
              />
              <div class="u-flex-0 u-spacing-ml-xxs u-spacing-mt-xxxs">
                {{ errorText.url }}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        class="u-flex-1 u-spacing-p-xxl u-display-flex u-flex-direction-column u-flex-justify-content-center u-spacing-pl-xxxl u-spacing-ml-m"
        :style="{ 'min-height': '100%' }"
      >
        <icon-text
          class="u-spacing-mb-s"
          :icon="'alerts'"
          :icon-classes="'rb-icon--medium u-color-grey-lighter'"
          :text="'Changes will reflect after review process'"
          :text-classes="'u-font-size-5'"
        />
        <div class="u-font-size-5 u-color-grey-lighter">
          Making changes in active profile and saving it while reinitiate
          campaign and might take 3-4 days to become active.We recommend you to
          make profile 2 as active before you send this to review.
        </div>
      </div>
    </div>
    <footer
      class="u-display-flex u-flex-align-items-center u-spacing-pv-m u-spacing-mt-xl u-spacing-ph-l u-border-width-s u-border-top u-border-bottom u-border-color-grey-xxx-light"
    >
      <rb-button
        :click-fn="apply"
        :text="'Apply'"
        :size="'s'"
        :type="'filled'"
        class="u-spacing-mr-m u-cursor-pointer"
        :disabled="applyDisabled"
        :class="{
          disabled: applyDisabled
        }"
      />
      <rb-button
        :click-fn="cancel"
        :text="'Cancel'"
        :size="'s'"
        :type="'hollow'"
        class="u-color-grey-light u-cursor-pointer"
      />
    </footer>
  </div>
</template>

<script>
import loader from '@/components/basic/loader.vue';
import iconText from '@/components/basic/iconText.vue';
import ImageGuideLinesTippyVue from '@/components/ams/campaign_creation/walmart_steps/profile/ImageGuideLinesTippy.vue';

export default {
  components: {
    loader,
    iconText,
    ImageGuideLinesTippyVue
  },
  props: {
    load: {
      default: false,
      type: Boolean
    },
    selections: {
      type: Array,
      default: function () {
        return [];
      }
    },
    config: {
      type: Object,
      default: () => ({ edit: false })
    }
  },
  data: () => ({
    brandName: null,
    headline: null,
    url: null,
    logo: null,
    isLoading: false,
    errorText: null
  }),
  computed: {
    isAllowed() {
      return !this.selections.length > 1;
    },
    applyDisabled() {
      const context = this;
      return ['brandName', 'headline', 'url', 'logo'].some(
        (key) => !context[key]
      );
    },
    computedData() {
      return {
        brandName: this.brandName,
        headline: this.headline,
        url: this.url,
        logo: this.logo
      };
    }
  },
  watch: {
    computedData: {
      handler: async function () {
        this.errorText = await this.validate();
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    if (this.config.edit) {
      this.init(this.config);
    }
  },
  methods: {
    async validate() {
      if (!this.config.validations) {
        return null;
      } else {
        let error = {};
        const validationObj = {
          logo: this.logo,
          headline: this.headline,
          brandName: this.brandName,
          url: this.url
        };
        for (let key in validationObj) {
          await this.config.validations[key](this[key], error);
        }
        return error;
      }
    },

    init(config) {
      this.brandName = config.brandName;
      this.url = config.url;
      this.headline = config.headline;
      this.logo = config.logo;
      this.$refs.logoRef.src = config.logo;
      this.isLoading = false;
    },
    chooseFile() {
      this.$refs.fileElm.click();
    },
    processFile(e) {
      const file = e.target?.files?.[0];
      this.logo = file;
      this.$refs.logoRef.src = URL.createObjectURL(this.logo);
      const context = this;
      this.$refs.logoRef.onload = function () {
        URL.revokeObjectURL(context.$refs.logoRef.src); // free memory
      };
    },
    updateProfileInfo(item) {
      const brandInfo = item;
      this.brandName = brandInfo?.brandName;
      this.headline = brandInfo?.headline;
      this.url = brandInfo?.url;
    },
    async apply() {
      let error = await this.validate();
      this.errorText = error;
      if (
        (this.errorText && !Object.keys(this.errorText).length) ||
        !this.errorText
      ) {
        this.$emit('apply', {
          logo: this.logo,
          headline: this.headline,
          brandName: this.brandName,
          url: this.url
        });
      }
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="css" scoped>
.select-asset:hover {
  background: #007cf6;
  cursor: pointer;
}
.select-asset:hover .asset-px {
  color: #fff;
}
.brand-name-logo {
  height: 86px;
  width: 105px;
  max-height: 86px;
  max-width: 105px;
  object-fit: contain;
  background: url('/images/upload_file.png') no-repeat scroll 0 0;
  background-size: contain;
  border-radius: 4px;
}
.show-loader {
  height: 65vh;
}
</style>
